export interface DeliveryList {
    hoSoId: number;
    maHoSo: string;
    ngayTao: string;
    tenNguoiTao: string;
    tenPhongBanNguoiTao: string;
    tenNguoiNhan: string;
    diaChiNguoiNhan: string;
    loaiDonDisplay: string;
    hinhThucChuyenDisplay: string;
    trangThaiDisplay: string;
    donViVanChuyenDisplay: string;
    donViVanChuyenURL: string;
    maVanDon: string;
    ngayCapNhanVanDon: string;
    isAllowTaoLai: true,
    isAllowEdit: true,
    isAllowDelete: true,
    isAllowXacNhan: true,
    isAllowNhapVanDon: true
}

export interface DeliveryDetail {
    hoSoId: number;
    tenNguoiNhan: string;
    diaChiNguoiNhan: string;
    ngayGiao: string;
    loaiDonKey: string;
    noiDung: string;
    soLuong: number;
    nguoiNhanKey: string;
    tenCongTyNhan: string;
    sdtNguoiNhan: string;
    donViVanChuyenKey: string;
    loaiBuuPhamKey: string;
    trongLuong: number;
    loaiThanhToanKey: string;
    dongGoiKey: string;
    hinhThucChuyenKey: string;
    [item: string]: any;
}

export enum EDeliveryType {
    NORMAL_EXPRESS = 'normal_express',
    SUPPER_EXPRESS = 'supper_express',
    PARCEL = 'parcel',
    INTERNATIONAL_EXPRESS = 'international_express'
    // HAND_EXPRESS = 'thu_chuyen_tan_tay',
    // HIGH_VALUE_EXPRESS = 'chuyen_hanghoa_giatricao',
    // NORMAL_VALUE_EXPRESS = 'chuyen_hanghoa_thuong'
}

export enum EDeliveryName {
    NORMAL_EXPRESS = 'Thư thường',
    SUPPER_EXPRESS = 'Thư hỏa tốc',
    PARCEL = 'Hàng hóa',
    INTERNATIONAL_EXPRESS = 'Quốc tế'
    // HAND_EXPRESS = 'Thư chuyển tận tay',
    // HIGH_VALUE_EXPRESS = 'Chuyển phát hàng hóa có giá trị cao (Chuyển phát đảm bảo)',
    // NORMAL_VALUE_EXPRESS = 'Chuyển phát hàng hóa thường'
} 