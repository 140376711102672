export const firebaseConfig = {
    apiKey: "AIzaSyA5hXScXsykwLLzwsFUo3ZtqIImk2hAUc0",
    authDomain: "tcg-land-bc350.firebaseapp.com",
    projectId: "tcg-land-bc350",
    storageBucket: "tcg-land-bc350.appspot.com",
    messagingSenderId: "548696050743",
    appId: "1:548696050743:web:851acf312cff1c701e43b2",
    measurementId: "G-K77WWRCZ3Z"
}

export const publicVapidKey = 'BDNhIfNsobBWjPWTjhGvm6Nrw6U9FbpVueY_JJXTWq6LIsE8MTRT_kKRgqUimym5qyA20e_y2lQ-2Nq-cCt4rbk'