import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SsoComponent } from './sso.component';

const routes: Routes = [{
  path: '',
  component: SsoComponent,
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SsoRoutingModule { }
