import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SsoMsalComponent } from './sso-msal.component';
import { SsoMsalRoutingModule } from './sso-msal-routing.module';
import { SSOMsalService } from './sso-msal.service';

@NgModule({
  declarations: [SsoMsalComponent],
  imports: [
    CommonModule,
    SsoMsalRoutingModule
  ],
  providers: [SSOMsalService]
})
export class SsoMsalModule { }
