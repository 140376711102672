import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-it-request',
  template: '<router-outlet></router-outlet>'
})
export class ItRequestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
