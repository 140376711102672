import { enableProdMode, StaticProvider } from '@angular/core'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import {
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorConfiguration
} from '@azure/msal-angular'
import { LogLevel, PublicClientApplication } from '@azure/msal-browser'

if (environment.production) {
  enableProdMode()
}

const isIEBrowser =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1

/**
 * msal logger
 * @param logLevel log level
 * @param message message log
 */
function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log("MSAL Angular: ", message);
}

/**
 * provider setting
 * @param param0 provider setting param
 * @returns provider
 */
function msalStaticProvider({ msal, guard, interceptor }): StaticProvider[] {
  return [
    {
      provide: MSAL_INSTANCE,
      useValue: new PublicClientApplication({
        auth: msal.auth,
        cache: {
          ...msal.cache,
          storeAuthStateInCookie: isIEBrowser
        },
        system: {
          loggerOptions: {
            loggerCallback,
            logLevel: LogLevel.Info,
            piiLoggingEnabled: false
          }
        }
      })
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: guard.interactionType,
        authRequest: guard.authRequest,
        loginFailedRoute: guard.loginFailedRoute
      } as MsalGuardConfiguration
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useValue: {
        interactionType: interceptor.interactionType,
        protectedResourceMap: new Map(interceptor.protectedResourceMap)
      } as MsalInterceptorConfiguration
    }
  ]
}
function fetchConfig() {
  // Fetch i18n
  Promise.all([
    fetch('/assets/i18n/en.json').then((res) => {
      return res.json()
    }),
    fetch('/assets/i18n/vi.json').then((res) => {
      return res.json()
    })
  ])
    .then(([langEn, langVi]) => {
      if (langVi && langEn) {
        // SetLang
        window['en'] = langVi
        window['vi'] = langEn
        // Fetch Config SSO
        fetch('/assets/env/sso.config.json')
          .then((res) => {
            return res.json()
          })
          .then((data) => {
            // Set Config
            window['sso'] = data?.sso
            window['isLoginNormal'] = data?.isLoginNormal
            // Load app
            platformBrowserDynamic(msalStaticProvider(data))
              .bootstrapModule(AppModule)
              .catch((err) => console.error(err))
          })
          .catch((err) => console.log(err))
      }
    })
    .catch((err) => console.log(err))
}

fetchConfig()
